import request from './_config'

const path = `autobot`

export function getAutobots(tiktok_account_id) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params: {}
    })
}

export function createAutobot(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data: {
            name: data.name,
            description: data.description,
            advertiser_id_list: data.advertiser_id_list
        }
    })
}

export function updateAutobot(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'put',
        data: {
            id: data._id,
            name: data.name,
            description: data.description,
            advertiser_list: data.advertiser_list,
            active: data.active
        }
    })
}

export function deleteAutobot(tiktok_account_id, autobot_id) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'delete',
        params: {
            id: autobot_id
        }
    })
}
