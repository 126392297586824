var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-drawer',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.m__loading),expression:"m__loading"}],attrs:{"size":"85%","confirm_to_close":false,"visible":_vm.visible_in_line},on:{"update:visible":function($event){_vm.visible_in_line=$event}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center justify-between flex-1 h-full pr-5"},[_c('div',[_c('p',{staticClass:"font-semibold text-sm"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.create_rule'))+" ")])]),_c('el-button',{attrs:{"icon":"el-icon-plus","type":"primary","size":"small"},on:{"click":_vm.handleCreateAutomatedRule}},[_vm._v(" "+_vm._s(_vm.$t('button.create'))+" ")])],1)]),_c('div',{staticClass:"px-5 py-8"},[_c('div',{staticClass:"component-box",attrs:{"id":"input_rule_name_wrapper"}},[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.rule_name'))+" ")]),_c('el-input',{staticClass:"flex-1",attrs:{"id":"input_rule_name","size":"small"},model:{value:(_vm.rule_name),callback:function ($$v) {_vm.rule_name=$$v},expression:"rule_name"}})],1),_c('div',{staticClass:"flex mt-4 space-x-2"},_vm._l((_vm.list_template_automated_rules),function(item,index){return _c('div',{key:index,staticClass:"component-box !p-3 w-[240px] h-auto border-[1px]",class:[
                    _vm.selected_template_automated_rule === item.id
                        ? 'border-brand-atosa'
                        : 'border-transparent',
                    item.supports.some(
                        (item) => item === _vm.selected_apply_object.dimension
                    )
                        ? 'cursor-pointer hover:border-brand-atosa'
                        : 'cursor-not-allowed opacity-60'
                ],on:{"click":function($event){return _vm.handleSelectTemplateAutomatedRule(item)}}},[_c('p',{staticClass:"text-sm font-semibold"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('p',{staticClass:"text-desc-text text-xs"},[_vm._v(" "+_vm._s(item.description)+" ")])])}),0),_c('div',{staticClass:"component-box flex flex-col mt-4 space-y-8"},[_c('div',[_c('div',{staticClass:"flex items-center mb-3"},[_c('div',{staticClass:"bg-brand-atosa--light util-flex-center p-2 rounded-md"},[_c('i',{staticClass:"el-icon-box text-white"})]),_c('p',{staticClass:"ml-2 text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.apply_rule'))+" ")])]),_c('p',{staticClass:"text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.select_rule_for'))+" ")]),_c('el-radio-group',{staticClass:"flex flex-col space-y-3",model:{value:(_vm.selected_apply_object.dimension),callback:function ($$v) {_vm.$set(_vm.selected_apply_object, "dimension", $$v)},expression:"selected_apply_object.dimension"}},[_c('el-radio',{attrs:{"label":'CAMPAIGN'}},[_c('span',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.campaign'))+" ")]),(
                                _vm.selected_apply_object.dimension ===
                                'CAMPAIGN'
                            )?_c('div',[_c('el-select',{staticClass:"mt-1 w-[350px]",attrs:{"value-key":"campaign_id","size":"small","multiple":"","placeholder":_vm.$t('input.placeholder.please_select'),"filterable":""},model:{value:(_vm.list_campaigns),callback:function ($$v) {_vm.list_campaigns=$$v},expression:"list_campaigns"}},_vm._l((_vm.m__data_for_pagination),function(item){return _c('el-option',{key:item.campaign_id,attrs:{"label":item.campaign_name,"value":item}})}),1)],1):_vm._e()]),_c('el-radio',{attrs:{"label":'ADGROUP'}},[_c('span',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.adgroup'))+" ")]),(
                                _vm.selected_apply_object.dimension ===
                                'ADGROUP'
                            )?_c('div',[_c('el-select',{staticClass:"mt-1 w-[350px]",attrs:{"value-key":"adgroup_id","size":"small","multiple":"","placeholder":_vm.$t('input.placeholder.please_select'),"filterable":""},model:{value:(_vm.list_adgroups),callback:function ($$v) {_vm.list_adgroups=$$v},expression:"list_adgroups"}},_vm._l((_vm.m__data_adgroup_for_pagination),function(item){return _c('el-option',{key:item.adgroup_id,attrs:{"label":item.adgroup_name,"value":item}})}),1)],1):_vm._e()])],1)],1),_c('div',[_c('div',{staticClass:"flex items-center mb-3"},[_c('div',{staticClass:"bg-brand-atosa--light util-flex-center p-2 rounded-md"},[_c('i',{staticClass:"el-icon-star-on text-white"})]),_c('p',{staticClass:"ml-2 text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.condition_and_action'))+" ")])]),_c('ConditionAndAction',{attrs:{"selected_conditions":_vm.selected_conditions,"selected_actions":_vm.selected_actions,"dimension":_vm.selected_apply_object.dimension,"currency":_vm.currency},on:{"update:selected_conditions":function($event){_vm.selected_conditions=$event},"update:selected_actions":function($event){_vm.selected_actions=$event}}})],1),_c('div',[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"bg-brand-atosa--light util-flex-center p-2 rounded-md"},[_c('i',{staticClass:"el-icon-s-tools text-white"})]),_c('p',{staticClass:"ml-2 text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.rule_settings'))+" ")])]),_c('RunSchedule',{attrs:{"rule_exec_info":_vm.rule_exec_info},on:{"update:rule_exec_info":function($event){_vm.rule_exec_info=$event}}}),_c('div',{staticClass:"flex items-center mt-6 space-x-2"},[_c('el-switch',{attrs:{"disabled":_vm.selected_actions.some(
                                (item) => item.subject_type === 'MESSAGE'
                            )},model:{value:(_vm.switch_notify),callback:function ($$v) {_vm.switch_notify=$$v},expression:"switch_notify"}}),_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.notify_me'))+" ")])],1),_c('p',{staticClass:"text-desc-text text-xs"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.receive_notifications_by_email'))+" ")]),(
                        _vm.notification.notification_type !==
                        'NOT_NOTIFICATION'
                    )?_c('NotifyMe',{staticClass:"mt-2",attrs:{"notification":_vm.notification},on:{"update:notification":function($event){_vm.notification=$event}}}):_vm._e()],1)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }